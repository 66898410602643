import React from 'react';

import './Features.css';

import sliderImage2 from '../../images/slide-image2.png';
import sliderImage3 from '../../images/slide-image3.png';
import sliderImage4 from '../../images/startup-new-business.png';

const Features = (props) => {
    return(
        <div className="features container-fluid">
            <div className="container">
                <div className="row rtl-row">
                    <div className="col-sm-5">
                        <div className="img-holder">
                            <img src={sliderImage3} alt="" />
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="feature-info">
                            <div className="feature-title">Built for your needs</div>
                            <div className="feature-text">Custom tailored to you and your customer's needs. </div>
                            <div className="feature-link"><a href="#" className="hbtn hbtn-default">Get Started!</a></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-5">
                        <div className="img-holder">
                            <img src={sliderImage2} alt="" />
                        </div>
                    </div>
                    <div className="col-sm-7 def-aligned">
                        <div className="feature-info">
                            <div className="feature-title">In a hurry? let’s start!</div>
                            <div className="feature-text">Deliver to your customers as fast as possible! </div>
                            <div className="feature-link"><a href="#" className="hbtn hbtn-default">Get Started!</a></div>
                        </div>
                    </div>
                </div>
                <div className="row rtl-row">
                    <div className="col-sm-5">
                        <div className="img-holder">
                            <img src={sliderImage4} alt="" />
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="feature-info">
                            <div className="feature-title">Grow with us</div>
                            <div className="feature-text">We take your business to the next level. From marketing, app development, maintenance, design and more!</div>
                            <div className="feature-link"><a href="#" className="hbtn hbtn-default">Get Started!</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features;