import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import logo from '../images/astradevlabs-logo.png';

const Admin = (props) => {

    useEffect(() => {
        document.body.classList.add('fullpage');
    });

    return (
        <div className="container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="login-register-form">
                            <div className="form-holder">
                                <div className="form-row form-head">
                                    <Link to="" style={{ marginLeft: 5 }}>
                                        <img style={{ width: 45 }} src={logo} alt="Astradevlabs"/>
                                        <span style={{ color: 'white', fontSize: 25, fontWeight: 'Bold', }}> astra</span>
                                        <span style={{ color: '#ffc600', fontSize: 25, fontWeight: 'Regular', }}>devlabs</span>
                                    </Link>
                                    <form style={{ marginTop: 50 }}>
                                        <div className="form-row">
                                            <div className="col-xs-12">
                                                <label>Username</label>
                                                <input type="text" className="form-control" required/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-xs-12">
                                                <label>Password</label>
                                                <input type="password" className="form-control" required/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-xs-6">
                                                <a href="#" className="forget-link">Forget your password?</a>
                                            </div>
                                            <div className="col-xs-6">
                                                <div className="submit-holder">
                                                    <button type="submit">Login</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin;