import React from 'react';

import Features from '../components/Features/Features';
import SimpleHeader from '../components/Headers/SimpleHeader';
import LatestNews from '../components/Blog/LatestNews';
import Footer from '../components/Footer/Footer';

const Blog = (props) => {
    return (
        <div>
            <SimpleHeader />
            <LatestNews />
            <Features />
            <Footer />
        </div>
    )
}

export default Blog;