import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../images/astradevlabs-logo.png';

const Navigation = (props) => {


    return (
        <nav id="nav" className="navbar navbar-full">
            <div className="container-fluid">
                <div className="container container-nav">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="navbar-header">
                                <button aria-expanded="false" type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <a className="navbar-brand" href="/">
                                    <img style={{ width: 45 }} src={logo} alt="Astradevlabs"/>
                                </a>
                                <div style={{ marginLeft: 60, marginTop: 30}}>
                                <span style={{ color: 'white', fontSize: 25, fontWeight: 'Bold', }}>
                                        astra
                                    </span>
                                    <span style={{ color: '#ffc600', fontSize: 25, fontWeight: 'Regular', }}>
                                        devlabs
                                    </span>
                                </div>
                            </div>
                            <div aria-expanded="false" className="navbar-collapse collapse navbar-collapse-centered" id="bs" style={{ marginTop: 10}}>
                                <ul className="nav navbar-nav navbar-nav-centered">
                                    <li className="nav-item">
                                        <NavLink to="/" className="nav-link" activeClassName="nav-link active">Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/works" className="nav-link" activeClassName="nav-link active">Works</NavLink>
                                    </li>
                                    {/* <li className="nav-item">
                                        <NavLink to="/services" className="nav-link" activeClassName="nav-link active">Services</NavLink>
                                    </li> */}
                                    <li className="nav-item">
                                        <NavLink to="/team" className="nav-link" activeClassName="nav-link active">Team</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/blog" className="nav-link" activeClassName="nav-link active">Blog</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/about" className="nav-link" activeClassName="nav-link active">About</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navigation;