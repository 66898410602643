import React from 'react';
import Slider from "react-slick";

import './TopContent.css';

import Slide from './Slide';

import sliderImage1 from '../../images/slide-image1.png';
import sliderImage2 from '../../images/slide-image2.png';
import sliderImage4 from '../../images/slide-image4.png';


const settings = {
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    fade: true
};

const TopContent = (props) => {
    return (
        <div id="top-content" className="container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="main-slider">
                            <Slider { ...settings }>
                                <Slide 
                                    heading1="Software"
                                    heading2="Built for you"
                                    subheading="Create your next mobile or web app with us!"
                                    buttonText="Services"
                                    image={sliderImage1}
                                />
                                <Slide 
                                    heading1="Collaboration"
                                    heading2="Defined"
                                    subheading="From design to deployment, we got you!"
                                    buttonText="Team"
                                    image={sliderImage4}
                                />
                                <Slide 
                                    heading1="User Experience"
                                    heading2="Reimagined"
                                    subheading="Designs that impact your business"
                                    buttonText="Works"
                                    image={sliderImage2}
                                />
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopContent;

