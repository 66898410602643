import React from 'react';

import SimpleHeader from '../components/Headers/SimpleHeader';
import Features from '../components/Features/Features';
import Pricing from '../components/Pricing/Pricing';
import Footer from '../components/Footer/Footer';

const Services = (props) => {
    return (
        <div>
            <SimpleHeader />
            <Pricing />
            <Footer />
        </div>
    )
}

export default Services;