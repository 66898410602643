import React from 'react';

const PostBox = (props) => {
    return (
        <div className="col-sm-6 col-md-4">
            <div className="post-box yellow-bg">
                <div className="post-img">
                    
                </div>
                <span className="badge badge-danger">New</span>
                <div className="post-title">ReactJS tips you should know about</div>
                <div className="post-link"><a href="#">Learn more</a></div>
            </div>
        </div>
    )
}

export default PostBox;