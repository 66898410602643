import React from 'react';

import './Pricing.css';
import PricingBox from './PricingBox';

const Pricing = (props) => {
    return (
        <div className="pricing container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="row-title">Our services</div>
                    </div>
                </div>
                <div className="row custom-padding">
                    <PricingBox />
                    <PricingBox />
                    <PricingBox />
                    <PricingBox />
                    <PricingBox />
                    <PricingBox />
                </div>
            </div>
        </div>
    )
}

export default Pricing;
