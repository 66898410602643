import React from 'react';

import SimpleHeader from '../components/Headers/SimpleHeader';
import Features from '../components/Features/Features';
import Footer from '../components/Footer/Footer';

const About = (props) => {
    return (
        <div>
            <SimpleHeader />
            <Features />
            <Footer />
        </div>
    )
}

export default About;