import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './Footer.css';

const Footer = (props) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div className="footer container-fluid">
            <Link to={scrollToTop} className="btn-go-top"><i className="hstb hstb-down-arrow"></i></Link>
            <div className="container">
                <div className="row">
                    <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2">
                        <div className="footer-menu">
                            <h4 style={{color:'white'}}>astra<span style={{color:'#ffc600'}}>devlabs</span></h4>
                            <ul>
                                <li><Link to="/" className="nav-link">Home</Link></li>
                                <li><Link to="/works" className="nav-link">Works</Link></li>
                                {/* <li><Link to="/services" className="nav-link">Services</Link></li> */}
                                <li><Link to="/team" className="nav-link">Team</Link></li>
                                <li><Link to="/blog" className="nav-link">Blog</Link></li>
                                <li><Link to="/about" className="nav-link">About</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2">
                        <div className="footer-menu">
                            {/* <h4>Works</h4>
                            <ul>
                                <li><a href="webhosting.html">Mobile Apps</a></li>
                                <li><a href="wordpresshosting.html">Web Apps</a></li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2">
                        <div className="footer-menu">
                            <h4>Services</h4>
                            <ul>
                                <li><a href="domains.html">Websites</a></li>
                                <li><a href="domains.html">Mobile Apps</a></li>
                                <li><a href="domains.html">Marketing</a></li>
                                <li><a href="domains.html">Design</a></li>
                                <li><a href="domains.html">Maintenance</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                        <div className="footer-menu">
                            <h4>Blog</h4>
                            <ul>
                                <li><a href="/">Trends</a></li>
                                <li><a href="/">Tutorials</a></li>
                            </ul>  
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        <div className="footer-menu custom-footer-menu">
                            <h4>Contact us</h4>
                            <ul className="social">
                                <li><a href="https://www.facebook.com/astradevlabs" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.youtube.com/c/TheKulasTV?sub_confirmation=1" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a></li>
                                <li><a href="https://twitter.com/astradevlabs" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="https://github.com/astradevlab" target="_blank" rel="noreferrer"><i className="fab fa-github"></i></a></li>
                            </ul>
                            <ul>
                                
                                <li><a href="https://m.me/astradevlabs" target="_blank" rel="noreferrer">Message us</a></li>
                                <li><a href="https://www.google.com/search?q=astradevlabs&sxsrf=ALeKk01Esr8jyuHbBFhKFK5cCWauMFvLrw%3A1624977502843&source=hp&ei=XjDbYObYMKWprtoPze-muAU&iflsig=AINFCbYAAAAAYNs-biJHAgr2_pux1E7_aybNMDkkP3Be&oq=astradevlabs&gs_lcp=Cgdnd3Mtd2l6EAMyAggmOgQIIxAnOggIABCxAxCDAToCCAA6BQgAELEDOgsILhCxAxDHARCjAjoFCC4QsQM6BAgAEAo6BwgAELEDEAo6DQguELEDEMcBEKMCEAo6CwguEMcBEK8BEJMCOggILhDHARCvAToQCC4QsQMQxwEQowIQChCTAjoHCAAQyQMQCjoFCAAQkgM6CgguEMcBEK8BEApQsgtYhR1gpx1oAXAAeACAAZcCiAGWDJIBBjAuMTAuMZgBAKABAaoBB2d3cy13aXo&sclient=gws-wiz&ved=0ahUKEwjm_8n2iL3xAhWllEsFHc23CVcQ4dUDCAc&uact=5#" target="_blank" rel="noreferrer">Our location</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sub-footer">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="sub-footer-menu">
                                <ul>
                                    <li><a href="/">Sitemap</a></li>
                                    <li><a href="/">Terms of Service</a></li>
                                    <li><a href="privacy.html">Privacy Policy</a></li>
                                </ul>        
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="copyright">© Copyright 2021 Astradevlabs, All Rights Reserved</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;