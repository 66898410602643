import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";

// Styles
import '../styles/bootstrap.min.css';
import '../styles/fontawesome-all.min.css';
import '../styles/slick.css';
import '../styles/styles-modified.css';
import '../styles/style.css';

//Scripts
import 'jquery/dist/jquery.min.js'; // Have to install and import jQuery because of bootstrap modal's dependency
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Pages
import Home from '../pages/Home';
import Works from '../pages/Works';
import Services from '../pages/Services';
import Team from '../pages/Team';
import About from '../pages/About';
import Blog from '../pages/Blog';
import Admin from '../pages/Admin';

//Helper
import ScrollToTop from './Helpers/ScrollToTop';


const App = () => {
    
    return (
        <div>
            <ScrollToTop />
            <Switch>
                <Route path="/works">
                    <Works />
                </Route>
                <Route path="/services">
                    <Services />
                </Route>
                <Route path="/team">
                    <Team />
                </Route>
                <Route path="/blog">
                    <Blog />
                </Route>
                <Route path="/about">
                    <About />
                </Route>
                <Route path="/admin">
                    <Admin />
                </Route>
                <Route path={["/"]}>
                    <Home />
                </Route>
            </Switch>
            <Helmet>
            <script src="https://unpkg.com/react/umd/react.production.min.js" crossorigin></script>

            <script
            src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"
            crossorigin></script>

            <script
            src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"
            crossorigin></script>
            </Helmet>
        </div>
    )
}

export default App;