import React from 'react';

import Navigation from './Navigation';

import './MainHeader.css';

const SimpleHeader = (props) =>{
    return (
        <div id="header-holder" style={{ paddingBottom: 5 }}>
            <Navigation />
        </div>
    );
}

export default SimpleHeader;