import React, { useEffect } from 'react';

import Features from '../components/Features/Features';
import MainHeader from '../components/Headers/MainHeader';
import Pricing from '../components/Pricing/Pricing';
import LatestNews from '../components/Blog/LatestNews';
import Footer from '../components/Footer/Footer';


const Home = (props) => {

    useEffect(() => {
        document.body.classList.remove('fullpage');
    });

    return (
        <div>
            <MainHeader />
            <LatestNews />
            <Footer />
        </div>
    )
}

export default Home;