import React from 'react';
import { Link } from 'react-router-dom';

import './LatestNews.css';
import PostBox from './PostBox';

const LatestNews = (props) => {
    return (
        <div className="latest-news container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="row-title">
                            Latest articles from our blog
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="main-btn-holder">
                            <Link to="/blog" className="hbtn hbtn-default">View more</Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <PostBox />
                    <PostBox />
                    <PostBox />
                </div>
            </div>
        </div>
    )
}

export default LatestNews;