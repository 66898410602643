import React from 'react';

const PricingBox = (props) => {
    return (
        <div className="col-sm-6 col-md-4">
            <div className="pricing-box">
                <div className="pricing-title">Mobile App</div>
                <div className="pricing-icon">
                    <div className="icon"><img src="" alt=""/></div>
                </div>
                <div className="pricing-details">
                    <ul>
                        <li>User-friendly app</li>
                        <li>Development costs</li>
                        <li>Deployment and maintenance for 1 year</li>
                    </ul>
                </div>
                <div className="pricing-price">
                    <div className="price-info">Starting at only</div>
                    <div className="price">
                        <span className="currency">₱</span><span className="num">2750</span><span className="duration">/day</span>
                    </div>
                </div>
                <div class="pricing-button">
                    <a href="webhosting.html">Get Started!</a>
                </div>
            </div>
        </div>
    )
}

export default PricingBox;