import React from 'react';
import './MainHeader.css';

import Navigation from './Navigation';
import TopContent from './TopContent';

const MainHeader = (props) => {
    return (
        <div id="header-holder">
            <div className="cloud-bg"></div>
            <Navigation />
            <TopContent/>
        </div>
    );
};

export default MainHeader;

