import React from 'react';
import { Link } from 'react-router-dom';


const Slide = (props) => {
    return(
        <div className="slide">
            <div className="row rtl-row">
                <div className="col-sm-5">
                    <div className="img-holder">
                        <img src={props.image} alt=""/>
                    </div>
                </div>
                <div className="col-sm-7">
                    <div className="b-text">{ props.heading1 }<br/>{ props.heading2 }.</div>
                    <div className="m-text">{ props.subheading }</div>
                    <Link to="/services" className="hbtn hbtn-primary hbtn-lg">{ props.buttonText }</Link>
                </div>
            </div>
        </div>
    )
}

export default Slide;